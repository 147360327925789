import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import { FaHotel } from "react-icons/fa";

function HotelsAutoCompleteList({
  hotels,
  setLocationInputState,
  setLocationQuery,
  setShowLocations,
  setNoAutoComplete,
}: any) {
  const { t: translate } = useTranslation();
  return (
    <> 
      <ul>
         {hotels.map((hotel: any) => {
          const alternativeFlagUrl = `https://macqueen.s3.eu-north-1.amazonaws.com/country_flags/flags_png/${hotel.country_code.toLowerCase()}.png`;

          return (
            <li
              className="flex gap-2 p-2 text-xs border-b rounded-none cursor-pointer hover:bg-gray-100 text-slate-600"
              key={hotel.id}
              onClick={() => {
                hotel.name &&
                  setLocationQuery(
                    hotel.id,
                    hotel.name.replace(/<em>/g, "").replace(/<\/em>/g, ""),
                    "hotel"
                  );
                hotel.name && setLocationInputState(hotel.name);
                setShowLocations(false);
                setNoAutoComplete(true);
              }}
            >
              <div className="shrink-0">
                <FaHotel className="rounded-none opacity-50" size={20} />
              </div>
              <div>
                <h3>
                  {" "}
                  {hotel.name &&
                    parse(
                      hotel.name.replace(/<em>/g, "").replace(/<\/em>/g, "")
                    )}
                </h3>{" "}
                <div className="flex gap-2 mt-1">
                  <div className="flex-shrink-0 mt-0.5 ">
                     <img
                      width={16}
                      height={16}
                      src={hotel.city_icon || alternativeFlagUrl}
                      className="rounded-none"
                    />
                  </div>
                  <p style={{ fontFamily: "Biennale", opacity: 0.5 }}>
                    {hotel.city_name}
                  </p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default HotelsAutoCompleteList;