import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface PreferenceState {
  currency: string;
  lang: string;
  appTheme: string;
  setCurrentCurrency: (currency: string) => void;
  setCurrentLang: (lang: string) => void;
  setAppTheme: (appTheme: string) => void;
}

export const usePreference = create(
  persist(
    (set) => ({
      currency: "QAR",
      lang: "en",
      appTheme: "green",
      setCurrentCurrency: (currency: string) => set({ currency }),
      setCurrentLang: (lang: string) => set({ lang }),
      setAppTheme: (appTheme: string) => set({ appTheme }),
    }),
    {
      name: "preference",
    }
  )
);
