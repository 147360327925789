import { apiServices } from "utils/configs/apiServices";
import { axiosInstance } from "utils/configs/axiosInstance";

export const getAppSettings = async () => {
  try {
    const response = await axiosInstance.get(
      apiServices.appSettings.getSettings
    );
    return response.data.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

 
export interface Currency {
  name: string;
  isoAlpha_3: string;
  icon: string;
  flag: string | null;
}

export const getCurrencies = async (): Promise<Currency[]> => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/currencies`;
  const {
    data: { data: currencies },
  } = await axiosInstance.get(url);
  return currencies;
};
